/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-22 10:07:01
 */
import api from "@/base/utils/request";

// 获取评论配置
export const ActivityCommentConfig = (data) => {
  return api({
    url: "admin/donation/settings/commentConfig",
    method: "post",
    data,
  });
};

// 获取所有词库
export const FilterWords = (data) => {
  return api({
    url: "admin/donation/settings/allWords",
    method: "post",
    data,
  });
};

// 保存评论配置
export const SaveCommentConfig = (data) => {
  return api({
    url: "admin/donation/settings/saveCommentConfig",
    method: "post",
    data,
  });
};
